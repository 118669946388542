<template>
  <section class="block main-mosaic-block">
    <div class="container">
      <div class="block-inner main-mosaic-inner">
        <div class="block-content main-mosaic-content">
          <ul class="main-mosaic-items">
            <li
              v-for="(item, idx) in items"
              :key="`main-mosaic-item-${idx}`"
              class="main-mosaic-item"
            >
              <NuxtImg
                class="main-mosaic-item-image"
                :src="String(item.image)"
                :alt="item.title"
              />
              <div class="main-mosaic-item-content">
                <div class="main-mosaic-item-top">
                  <p
                    v-if="item.title"
                    v-html="item.title"
                    class="main-mosaic-item-title"
                  ></p>
                  <p
                    v-if="item.text"
                    v-html="item.text"
                    class="main-mosaic-item-description"
                  >
                  </p>
                </div>
                <div class="main-mosaic-item-bottom">
                  <nuxt-link
                    class="btn primary outlined main-mosaic-item-link"
                    :to="item.link"
                  >
                    {{ item.link_text }}
                  </nuxt-link>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type {IBlockDefaultProps} from "#sitis/internal/models/common";
import type {ComputedRef} from "vue";

const props = defineProps<IBlockDefaultProps>();

const items: ComputedRef<any[]> = computed(() => {
  return props.values?.blocks || []
});
</script>

<style lang="scss">
.main-mosaic-block {
  .container {
    padding: 0;
  }
}

.main-mosaic-items {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.main-mosaic-item {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 430px;
  padding: 11px 48px 40px;
  z-index: 1;
  display: flex;
  align-items: flex-start;

  &:first-child {
    grid-column: 1 / 3;
  }

  &:nth-child(2) {
    grid-column: 3 / 4;
  }

  &:nth-child(3) {
    grid-column: 1 / 2;
  }

  &:nth-child(4) {
    grid-column: 2 / 4;
  }

  &:first-child,
  &:nth-child(4) {
    align-items: flex-end;
  }
}

.main-mosaic-item-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.main-mosaic-item-content {
  background: rgb(255 255 255 / 60%);
  padding: 33px 47px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.main-mosaic-item-top {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.main-mosaic-item-title {
  font-size: 36px;
  line-height: 47px;
  color: #000;
}

.main-mosaic-item-description {
  font-size: 18px;
  line-height: 23px;
  color: #000;
}

.main-mosaic-item-bottom {
  display: flex;
  flex-direction: column;
}

.main-mosaic-item-link {
  align-self: flex-end;
}

@media (max-width: 1023px) {
  .main-mosaic-inner {
    padding: 3px 0;
  }
  .main-mosaic-items {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
  }
  .main-mosaic-items .main-mosaic-item {
    &:nth-child(1), &:nth-child(4) {
      width: calc(100% - 3px);
      order: 1;
    }
    &:nth-child(2), &:nth-child(3) {
      width: calc(50% - 3px);
      order: 2;
    }
  }

  .main-mosaic-item {
    display: flex;
    flex-direction: column;
    min-height: initial;
    position: relative;
    overflow: hidden;
    align-items: flex-start!important;
    padding: 17px 7px;
    height: auto;

    &:before {
      content: "";
      float: left;
      padding-top: 46%;
    }
  }
  .main-mosaic-item-content {
    padding: 15px;
  }
  .main-mosaic-item-top {
    gap: 9px;
  }
  .main-mosaic-item-title {
    font-size: 18px;
    line-height: 23px;
  }
  .main-mosaic-item-description {
    font-size: 12px;
    line-height: 16px;
  }
  .main-mosaic-item-bottom {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
  }
  .main-mosaic-item-link {
    font-size: 12px;
    line-height: 16px;
    padding: 9px 19px;
  }
}
</style>
